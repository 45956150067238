var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{staticClass:"rounded-0 text-white",attrs:{"color":"primary","height":"80px"}},[_c('v-toolbar-title',{staticClass:"ml-3"},[_c('h2',[_c('b',[_vm._v("Tanımlar")]),_vm._v(" - Mesaj Şablon Listesi")])]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-items-center"},[_c('v-icon',{directives:[{name:"permission",rawName:"v-permission",value:({
          permissions: ['message-templates-delete'],
        }),expression:"{\n          permissions: ['message-templates-delete'],\n        }"}],staticClass:"mr-2",attrs:{"color":"error cursor-pointer","disabled":!_vm.isSelectable,"dark":""},on:{"click":_vm.showDeleteMessageTemplateModal}},[_vm._v(" mdi-delete ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":"","color":"cursor-pointer"}},'v-icon',attrs,false),on),[_vm._v(" mdi-reload ")])]}}])},[_c('span',[_vm._v("Yenile")])]),_c('v-text-field',{staticClass:"mx-4 mt-0 pt-0",attrs:{"label":"Ara","hide-details":"auto","single-line":"","dark":"","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('router-link',{attrs:{"to":{
          name: 'def-message-templates-edit',
          query: { editable: true },
        }}},[_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:({
            permissions: ['message-templates-create'],
          }),expression:"{\n            permissions: ['message-templates-create'],\n          }"}],attrs:{"elevation":"0","color":"success"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Yeni Mesaj Şablonu ")],1)],1)],1),_c('KTTopMenu')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.messageTemplates,"search":_vm.search,"single-select":true,"loading":_vm.loading,"sort-by":"system","sort-desc":"true","show-select":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('router-link',{directives:[{name:"permission",rawName:"v-permission",value:({
                permissions: ['message-templates-update'],
              }),expression:"{\n                permissions: ['message-templates-update'],\n              }"}],attrs:{"to":{
                name: 'def-message-templates-edit',
                params: { id: item.id },
                query: { editable: true },
              }}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Düzenle")])])]}},{key:"item.subject",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'def-message-templates-edit',
            params: { id: item.id },
            query: { editable: false },
          }}},[_vm._v(" "+_vm._s(item.subject)+" ")])]}}]),model:{value:(_vm.selectedMessageTemplate),callback:function ($$v) {_vm.selectedMessageTemplate=$$v},expression:"selectedMessageTemplate"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }