<template>
  <v-card>
    <v-toolbar color="primary" class="rounded-0 text-white" height="80px">
      <v-toolbar-title class="ml-3">
        <h2><b>Tanımlar</b> - Mesaj Şablon Listesi</h2>
      </v-toolbar-title>
      <v-spacer />
      <div class="d-flex align-items-center">
        <v-icon
          color="error cursor-pointer"
          :disabled="!isSelectable"
          class="mr-2"
          dark
          v-permission="{
            permissions: ['message-templates-delete'],
          }"
          @click="showDeleteMessageTemplateModal"
        >
          mdi-delete
        </v-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon dark color="cursor-pointer" v-bind="attrs" v-on="on">
              mdi-reload
            </v-icon>
          </template>
          <span>Yenile</span>
        </v-tooltip>
        <v-text-field
          v-model="search"
          label="Ara"
          class="mx-4 mt-0 pt-0"
          hide-details="auto"
          single-line
          dark
          append-icon="search"
        ></v-text-field>
        <router-link
          :to="{
            name: 'def-message-templates-edit',
            query: { editable: true },
          }"
        >
          <v-btn
            v-permission="{
              permissions: ['message-templates-create'],
            }"
            elevation="0"
            color="success"
          >
            <v-icon small>mdi-plus</v-icon>
            Yeni Mesaj Şablonu
          </v-btn>
        </router-link>
      </div>
      <KTTopMenu></KTTopMenu>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        v-model="selectedMessageTemplate"
        :headers="headers"
        :items="messageTemplates"
        :search="search"
        :single-select="true"
        :loading="loading"
        sort-by="system"
        sort-desc="true"
        show-select
        item-key="id"
      >
        <template #item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <router-link
                v-permission="{
                  permissions: ['message-templates-update'],
                }"
                :to="{
                  name: 'def-message-templates-edit',
                  params: { id: item.id },
                  query: { editable: true },
                }"
              >
                <v-icon color="warning" small v-bind="attrs" v-on="on">
                  mdi-pencil
                </v-icon>
              </router-link>
            </template>
            <span>Düzenle</span>
          </v-tooltip>
        </template>
        <template #item.subject="{ item }">
          <router-link
            :to="{
              name: 'def-message-templates-edit',
              params: { id: item.id },
              query: { editable: false },
            }"
          >
            {{ item.subject }}
          </router-link>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import KTTopMenu from "@/view/layout/header/TopMenu.vue";
import {
  FETCH_MESSAGE_TEMPLATES,
  DELETE_MESSAGE_TEMPLATE,
} from "@/core/services/store/definitions/message-templates.module";

export default {
  name: "MessageTemplateList",
  components: { KTTopMenu },
  data() {
    return {
      headers: [
        { value: "subject", text: "Konu", sortable: false, align: "left" },
        { value: "content", text: "İçerik", sortable: false, align: "left" },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "left",
        },
        { text: "", value: "data-table-select", align: "right" },
      ],
      selectedMessageTemplate: [],
      search: "",
      loading: true,
    };
  },
  mounted() {
    this.fetchMessageTemplates().then(() => (this.loading = false));
  },
  methods: {
    ...mapActions([FETCH_MESSAGE_TEMPLATES, DELETE_MESSAGE_TEMPLATE]),
    async deleteTemplate() {
      try {
        await this.deleteMessageTemplate(this.selectedMessageTemplate[0]);
        this.$toast.success("Mesaj şablonu silindi", {
          position: "bottom-right",
          duration: 2000,
        });
        this.selectedMessageTemplate = [];
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    showDeleteMessageTemplateModal() {
      const params = {
        title: "Seçtiğiniz mesaj şablonu silinecek",
        text: "Seçili mesaj şablonunu silmek istediğinize emin misiniz?",
        onConfirm: () => {
          return this.deleteTemplate();
        },
      };
      this.$approvalModal.show(params);
    },
  },
  computed: {
    ...mapGetters(["messageTemplates"]),
    isSelectable() {
      return this.selectedMessageTemplate.length > 0;
    },
  },
};
</script>
